<template>
  <div class="clientes">
    <div class="row justify-content-between">
      <div class="col-auto input-group mb-3">
        <button v-on:click="handleAdicionar()" class="btn btn-success">
          <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Adicionar
        </button>
        <button v-on:click="handleOpenConfig()" class="btn btn-secondary">
          <font-awesome-icon :icon="['fas', 'cog']" fixed-width class=""/>
        </button>
      </div>

      <div class="col input-group mb-3">
        <input
            v-model="search.description"
            v-on:keyup.enter="handlePesquisa()"
            type="text"
            class="form-control"
            placeholder="Pesquisar"
            aria-label="Pesquisar"
            aria-describedby="button-addon2">
        <button v-on:click="handlePesquisa()" class="btn btn-outline-secondary" type="button" id="button-addon2">
          <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
        </button>
      </div>
    </div>
    <div class="d-flex">
      <table class="table table-sm-pc table-striped table-hover">
        <thead>
          <tr>
            <th>Identificação</th>
            <th width="175">Velocidade</th>
            <th width="85">Valor</th>
            <th width="75"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="plano in listaPlanos" :key="plano.id" :class="cssPlan(plano)">
              <td scope="row">{{plano.description}}</td>
              <td>
                <span v-if="(!plano.bandwidthControl)">-</span>
                <span v-if="(plano.bandwidthControl)">{{plano.download}}kbps / {{plano.upload}}kbps</span>
              </td>
              <td>R$ {{moedaToStringBRL(plano.price)}}</td>
              <td class="text-end">
                <button type="button" v-on:click="handleEditaPlanoISP(plano)" class="btn btn-primary btn-sm me-1">
                  <font-awesome-icon :icon="['fas', 'edit']" fixed-width/>
                </button>
                <button type="button" v-on:click="handleRemovePlanoISP(plano)" class="btn btn-danger btn-sm">
                  <font-awesome-icon :icon="['fas', 'times']" fixed-width/>
                </button>
              </td>

            </tr>
          </tbody>
      </table>
    </div>
    <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>

    <!-- modal-edita-plano-isp -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-edita-plano-isp" id="btn-modal-edita-plano-isp-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-edita-plano-isp" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="!modalPlanoISP.data.id" class="modal-title">
              <font-awesome-icon :icon="['fas', 'plus']" fixed-width/>
              Cadastrar novo Plano de Acesso
            </h5>
            <h5 v-if="!!modalPlanoISP.data.id" class="modal-title">
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width/>
              Editando Plano de Acesso
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 mb-2">
                <div class="form-check ps-0">
                  <div class="form-check form-switch">
                    <input v-model="modalPlanoISP.data.active" class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Ativo</label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-4 col-lg-8 mb-3">
                <label class="required">Identificação</label>
                <input type="text" v-model="modalPlanoISP.data.description" class="form-control" placeholder="Nome do plano">
              </div>
              <div class="col-12 col-sm-4 col-lg-2 mb-3">
                <label>Valor</label>
                <div class="input-group">
                  <span class="input-group-text">R$</span>
                  <CurrencyInput v-model="modalPlanoISP.data.price" :options="shared.moeda.currencyInputOptions()" placeholder="0,00" class="form-control text-end"/>
                </div>
              </div>
              <div class="col-12 col-sm-4 col-lg-2 mb-3">
                <label>Desc. pg. adiantado</label>
                <div class="input-group">
                  <span class="input-group-text">R$</span>
                  <CurrencyInput v-model="modalPlanoISP.data.boletoDiscount" :options="shared.moeda.currencyInputOptions()" placeholder="0,00" class="form-control text-end"/>
                </div>
                <small id="helpId" class="form-text text-muted">*Para pagamentos via boleto</small>
              </div>
              <div class="col-12">
                <div class="input-group">
                  <label class="custom-control custom-checkbox">
                    <input type="checkbox" v-model="modalPlanoISP.data.bandwidthControl" id="apply-bandwidth-control" class="custom-control-input">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">
                      <label for="apply-bandwidth-control" class="required ps-1">Aplicar controle de banda</label>
                    </span>
                  </label>
                </div>
            </div>
              <div class="col-12 col-sm-6 col-lg-3">
                <label class="required">Velocidade de Download</label>
                <div class="input-group mb-3">
                  <input type="text"
                      v-model.number="modalPlanoISP.data.download"
                      v-bind:disabled="!modalPlanoISP.data.bandwidthControl"
                      class="form-control"
                      placeholder="">
                  <span class="input-group-text">kbps</span>
                </div>
            </div>
              <div class="col-12 col-sm-6 col-lg-3">
                <label class="required">Velocidade de Upload</label>
                <div class="input-group mb-3">
                  <input type="text"
                      v-model.number="modalPlanoISP.data.upload"
                      v-bind:disabled="!modalPlanoISP.data.bandwidthControl"
                      class="form-control"
                      placeholder="">
                  <span class="input-group-text">kbps</span>
                </div>
            </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-edita-plano-isp-fecha">Fechar</button>
            <button type="button"
                v-if="!modalPlanoISP.data.id"
                v-on:click="handleModalPlanoISPCadastrar()"
                v-bind:disabled="!podeSalvarPlanoISP()"
                class="btn btn-success">
              Cadastrar
            </button>
            <button type="button"
                v-if="!!modalPlanoISP.data.id"
                v-on:click="handleModalPlanoISPSalvar()"
                v-bind:disabled="!podeSalvarPlanoISP()"
                class="btn btn-success">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-edita-plano-isp -->

    <!-- modal-edita-config-subscription -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-edita-config-subscription" id="btn-modal-edita-config-subscription-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-edita-config-subscription" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'cog']" fixed-width/>
              Configurações de assinatura
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <label class="required">Valor padrão para bloqueio, após quantos dias?</label>
                <input type="number" v-model.number="modalConfig.data.dueDays" class="form-control" placeholder="dias">
                <small class="text-danger">Os bloqueios não ocorrem nos finais de semana.</small>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-edita-config-subscription-fecha">Fechar</button>
            <button type="button"
                v-on:click="handleSaveConfig()"
                v-bind:disabled="!canSaveConfig()"
                class="btn btn-success">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-edita-config-subscription -->
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import moeda from '@/modules/moeda'
import clone from '@/modules/clone'
import model from '@/model'
import CurrencyInput from '@/components/CurrencyInput.vue'
import shared from '../modules/shared'

export default {
  name: 'CatalogoPlanoISP',
  components: {
    Paginador,
    CurrencyInput,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      listaPlanos: [],
      search: {
        description: '',
      },
      modalPlanoISP: {
        data: {
          id: undefined,
          active: false,
          description: '',
          bandwidthControl: false,
          price: '0,00',
          boletoDiscount: 0,
          download: 0,
          upload: 0,
          download_contrato: 0,
          upload_contrato: 0,
        },
      },
      modalConfig: {
        data: {
          dueDays: 0,
        },
      },
    }
  },
  methods: {
    handlePesquisa() {
      let apiUrl = `/isp/plans?${this.paginador.requestParams}&orderBy[active]=desc&orderBy[description]=asc`;
      if (this.search.description) {
        apiUrl += `&description[like]=${this.search.description}`;
      }

      loading(true);
      api.get(apiUrl).then(res => {
        loading(false);
        this.listaPlanos = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    handleAdicionar() {
      this.resetmodalPlanoISP();
      this.abreModalCadastroPlanoISP();
    },

    async handleEditaPlanoISP(plano) {
      this.resetmodalPlanoISP();

      this.modalPlanoISP.data = clone(plano);
      // this.modalPlanoISP.data.price = moeda.toStringBrl(plano.price);

      this.abreModalCadastroPlanoISP();
    },

    handleRemovePlanoISP(plano) {
      dialogo.confirmacao(`Confirma remover o Plano de Acesso <b>${plano.description}</b>?`).then(() => {
        loading(true);

        api.delete(`/isp/plans/${plano.id}`).then(() => {
          loading(false);

          this.handlePesquisa();
        }).catch(error => {
          loading(false);
          dialogo.erroHttp(error);
        });
      });
    },

    abreModalCadastroPlanoISP() {
      document.getElementById('btn-modal-edita-plano-isp-abre').click();
    },

    resetmodalPlanoISP() {
      this.modalPlanoISP.data.id = undefined;
      this.modalPlanoISP.data.description = '';
      this.modalPlanoISP.data.price = 0;
      this.modalPlanoISP.data.boletoDiscount = 0;
      this.modalPlanoISP.data.download = 0;
      this.modalPlanoISP.data.upload = 0;
    },

    podeSalvarPlanoISP() {
      // const price = moeda.fromString(this.modalPlanoISP.data.price);

      switch (true) {
        case (this.modalPlanoISP.data.description.length < 3):
        case (this.modalPlanoISP.data.price < 0):
        case (this.modalPlanoISP.data.boletoDiscount < 0):
        case (this.modalPlanoISP.data.bandwidthControl && this.modalPlanoISP.data.download <= 0):
        case (this.modalPlanoISP.data.bandwidthControl && this.modalPlanoISP.data.upload <= 0):
          return false;

        default:
          return true;
      }
    },

    handleModalPlanoISPCadastrar() {
      const sendData = this.modalPlanoISP.data;
      // sendData.price = moeda.fromString(this.modalPlanoISP.data.price);

      loading(true);
      api.post('/isp/plans', sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-plano-isp-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handleModalPlanoISPSalvar() {
      const sendData = this.modalPlanoISP.data;
      // sendData.price = moeda.fromString(this.modalPlanoISP.data.price);

      loading(true);
      api.put(`/isp/plans`, sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-plano-isp-fecha').click();
        this.handlePesquisa();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    moedaToStringBRL(entrada) {
      return moeda.toStringBrl(entrada);
    },

    modalPlanoChangeValor() {
      const price = moeda.fromString(this.modalPlanoISP.data.price);

      this.modalPlanoISP.data.price = moeda.toStringBrl(price);
    },

    modalPlanoValorCursorEnd() {
      const myInput = document.getElementById('modal-plano-isp-price');
      const pos = this.modalPlanoISP.data.price.length;

      myInput.setSelectionRange(pos, pos);

      setTimeout(function() {
        myInput.setSelectionRange(pos, pos);
      }, 100);

      setTimeout(function() {
        myInput.setSelectionRange(pos, pos);
      }, 300);

      setTimeout(function() {
        myInput.setSelectionRange(pos, pos);
      }, 500);

    },

    cssPlan(plan) {
      switch (plan.active) {
        case false:
          return 'text-muted-2';

        case true:
          return 'text-info';
      }
    },


    /////////////////////////////////////////////////////////////////
    handleOpenConfig() {
      loading(true);

      api.get(`/configs/subscription`).then(res => {
        this.modalConfig.data = res.data;
        loading(false);
        document.getElementById('btn-modal-edita-config-subscription-abre').click();      
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    canSaveConfig() {
      switch (true) {
        case (!this.modalConfig.data.dueDays || this.modalConfig.data.dueDays < 0):
          return false;
      }

      return true;
    },

    handleSaveConfig() {
      const sendData = {
        dueDays: this.modalConfig.data.dueDays,
      }

      loading(true);
      api.post(`/configs/subscription`, sendData).then(() => {
        loading(false);
        document.getElementById('btn-modal-edita-config-subscription-fecha').click();      
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    // v-on:click="handleSaveConfig()"
    //             v-bind:disabled="!canSaveConfig()"

    /////////////////////////////////////////////////////////////////
  },

  created() {
    this.handlePesquisa();

    // document.getElementById('btn-modal-edita-plano-isp-fecha').click();
    /*
    setTimeout(() => {
      this.handleAdicionar();

      this.modalPlanoISP.data.description = 'Plano de Acesso Teste Front';
      this.modalPlanoISP.data.senha_radius = '123465';
      this.modalPlanoISP.data.pool_ipv4_acesso_id = 0;
      this.modalPlanoISP.data.pool_ipv4_bloqueio_id = 0;
    }, 500);
    /* */
  },

  computed: {
    shared() {
      return shared;
    },
  }
}
</script>
