<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Catálogo</h1>
    <div class="form-group card-principal">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a class="nav-link active" id="nav-bng-tab" data-bs-toggle="tab" href="#nav-bng" role="tab" aria-controls="nav-bng" aria-selected="true">
            <font-awesome-icon :icon="['fas', 'globe']" fixed-width class="me-1"/>
            Planos de acesso
          </a>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <!-- bng's -->
        <div class="tab-pane fade show active" id="nav-bng" role="tabpanel" aria-labelledby="nav-bng-tab">
          <CatalogoPlanoISP/>
        </div>
        <!-- bng's -->

        <!-- contatos -->
        <div class="tab-pane fade" id="nav-contatos" role="tabpanel" aria-labelledby="nav-contatos-tab">
        </div>
        <!-- contatos -->

        <!-- endereços -->
        <div class="tab-pane fade" id="nav-enderecos" role="tabpanel" aria-labelledby="nav-enderecos-tab">
        </div>
        <!-- endereços -->
      </div>
    </div>
  </div>
</template>

<script>
import CatalogoPlanoISP from '@/views/CatalogoPlanoISP.vue'

export default {
  name: 'Catalogo',
  components: {
    CatalogoPlanoISP,
  },
  data() {
    return {}
  },
}
</script>
